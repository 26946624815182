import { useApolloClient } from "@apollo/client/main.cjs";
import { queryShopEligibleForSubscriptions } from "@smartrr/shared/shopifyGraphQL/shop";
import { captureException } from "@smartrr/shared/utils/captureException";
import { useEffect, useState } from "react";

export const useSubscriptionEligibility = () => {
  const [eligibleForSubscriptions, setEligibleForSubscriptions] = useState(true);
  const apolloClient = useApolloClient();

  useEffect(() => {
    checkEligibleForSubscriptions();
  }, []);

  const checkEligibleForSubscriptions = async () => {
    try {
      const res = await queryShopEligibleForSubscriptions(apolloClient);

      if (res.type === "success") {
        setEligibleForSubscriptions(res.body.data?.shop?.features.eligibleForSubscriptions);
      }
    } catch (error) {
      captureException("Unable to query shop's subscription eligibility", error);
    }
  };

  return { eligibleForSubscriptions };
};

export default useSubscriptionEligibility;
